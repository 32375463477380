
.dial {
  position: relative;
  width: 120px;
  height: 120px;
  /* background: #ececec; */
  
  border: 3px solid #fff; 
  /* #fc4349 */
  border-radius: 50%;
  margin: 8% auto 0;
  /* box-shadow: 1px 14px 21px 0 rgba(0,0,0,.2); */
  box-shadow: 2px 4px 6px rgba(0,0,0,.7);
  background: url('https://memoriesmakeredu.com/images/clocks/hours-minuts.png');
  background-size: cover;
}

/* .dial1{background-image: url(../public/images/clocks/taj.jpg); background-size: 80%; background-position: center; background-repeat: no-repeat;} */
.dial1{background-color: #2c3e50;}
.dial2{background-color: #2c3e50;}
.dial3{background-color: #2c3e50;}
.dial4{background-color: #2c3e50;}
.dial5{background-color: #2c3e50;}
/* 
.dial2{background-image: url(../public/images/clocks/ifeal.png);}
.dial3{background-image: url(../public/images/clocks/burj.png); background-position: center;}
.dial4{background-image: url(../public/images/clocks/merlion.webp); background-position: 70% bottom; background-repeat: no-repeat; background-size: 40%;}
.dial5{background-image: url(../public/images/clocks/liberty-statue.jpg);} */

span {
  display: inline-block;
}

.dot {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: radial-gradient(ellipse 20px 15px at 50% 0, #ececec 40%, #5c5c5c 80%);
  
  z-index: 10;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.3);
}
.sec-hand:after{
  position: absolute;
  content: '';
  top: 68%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background-color: green;
}
.sec-hand {
  position: absolute;
  top: 10.5px;
  left: 50%;
  margin-left: -8px;
  width: 16px;
  
  height: 68px;
  background: url('https://memoriesmakeredu.com/images/clocks/handle-second.png') 50% 50%;
  
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 9;
  transform-origin: 50% 68%;
  
  
}


.sec-hand.shadow {
  top: 46px;
  margin-left: -4px;
  background-image: url('https://themegion.github.com/AnalogClock/sec-shdw.svg');
  opacity: .1;
  z-index: 8;
  filter: blur(2px);
}

.min-hand {
  position: absolute;
  top: 20px;  
  left: 50%;
  margin-left: -8.5px;
  width: 16px;
  height: 40px;
  background: url('https://memoriesmakeredu.com/images/clocks/handle-minute.png') 50% 50% no-repeat;  
  background-size: contain;
  z-index: 7;
  transform-origin: 50% 93%;
  
}

/* .min-hand.shadow {
  top: 58px;
  margin-left: -5px;
  background-image: url('https://themegion.github.com/AnalogClock/min-shdw.svg');
  opacity: .1;
  z-index: 6;
  filter: blur(2px);
} */

.hour-hand {
  position: absolute;
  top: 28px;
  left: 50%;
  margin-left: -8px;
  width: 16px;
  height: 32px;
  background: url('https://memoriesmakeredu.com/images/clocks/handle-hour.png') 50% 50% no-repeat;
  background-size: contain;
  z-index: 5;
  transform-origin: 50% 92%;
  
  
}

.hour-hand.shadow {
  top: 92px;
  margin-left: -4px;
  background-image: url('https://themegion.github.com/AnalogClock/hour-shdw.svg');
  opacity: .1;
  z-index: 4;
  filter: blur(2px);
}

.twelve,
.three,
.six,
.nine {
  position: absolute;
  font-family: Lato, sans-serif;
  font-size: 16px;
  color: #fff;
}

.twelve {
  top: 13px;
  left: 47px;
}

.three {
  top: 48px;
  right: 18px;
}

.six {
  left: 53px;
  bottom: 11px;
}

.nine {
  top: 48px;
  left: 17px;
}

.diallines {
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -1px;
  width: 1px;
  height: 6px;
  background: #b3b3b0;
  z-index: 1;
  transform-origin: 50% 56px;
}

.diallines:nth-of-type(5n) {
  position: absolute;
  top: 2px;
  left: 50%;
  margin-left: -2px;
  width: 3px;
  height: 12px;
  background: #b3b3b0;
  z-index: 1;
  transform-origin: 50% 55px;
}

.date{
  position: absolute;
  top: 242px;
  left: 50%;
  margin-left: -18px;
  width: 36px;
  height: 30px;
  background: #83837a;
  border-radius: 6px;
  font-family: 'Fjalla One', sans-serif;
  font-size: 21px;
  line-height: 30px;
  color: white;
  text-align: center;
  box-shadow: inset 0 2px 2px 0 rgba(0,0,0,.3), inset 0 -2px 2px 0 rgba(255,255,255,.2);
  display: none;
}



