@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", serif;
} */

.main-head {
  height: 100%;
  min-height: 100vh;
  background: url("/src/assets/bg.png") #030728 no-repeat center;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
}

#root {
  width: 100%;
}

.currency-converter {
  margin: 0 auto 0 auto;
  padding: 40px 30px 50px;
  border-radius: 8px;
  max-width: 410px;
  backdrop-filter: blur(30px);
  background: rgba(2, 7, 40, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
}

.currency-converter .converter-title {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
}

.currency-converter .converter-form {
  margin-top: 45px;
}

.currency-converter .form-group {
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
}

.currency-converter .form-group .form-label {
  color: #fff;
  font-weight: 500;
  display: flex;
  margin-bottom: 9px;
  font-size: 1rem;
}

.currency-converter .form-group .form-input {
  outline: none;
  font-size: 1.1rem;
  padding: 0 15px;
  color: #fff;
  font-weight: 500;
  min-height: 48px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.currency-converter .form-currency-group {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form-currency-group .currency-select {
  display: flex;
  padding: 0 10px;
  min-height: 45px;
  align-items: center;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.form-currency-group .currency-select img {
  width: 25px;
}

.form-currency-group .currency-select .currency-dropdown {
  outline: none;
  border: none;
  background: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 10px 0 5px;
}

.form-currency-group .currency-select .currency-dropdown option {
  color: #000;
  font-weight: 500;
}

.form-currency-group .swap-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.2s ease;
}

.form-currency-group .swap-icon:hover {
  background: rgba(255, 255, 255, 0.3);
}

.converter-form .submit-button {
  width: 100%;
  min-height: 52px;
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s ease;
}

.converter-form .submit-button.loading {
  opacity: 0.8;
  pointer-events: none;
}

.converter-form .submit-button:hover {
  background: rgba(255, 255, 255, 0.8);
}

.converter-form .exchange-rate-result {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  padding: 25px 0;
  margin-top: 30px;
  border-radius: 6px;
  letter-spacing: 0.5px;
  background: rgba(255, 255, 255, 0.15);
}
