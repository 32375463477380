@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #fcfcfc;
}

/* a[href="https://elfsight.com/google-reviews-widget/?utm_source=websites&utm_medium=clients&utm_content=google-reviews&utm_term=localhost:3000&utm_campaign=free-widget"]{
        
  display: none !important;
} */

.eyarYd a {
  display: none !important;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  background-color: #999;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #bbb;
}

.customContainer {
  max-width: 100%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  margin-top: 100px;
}

.monumentsCarousel .swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 3px;
}

/* HEADER START */

/* feedback icon */

.parisTourAdvBtnBox {
  position: fixed;
  bottom: -35px;
  left: -85px;
  background: linear-gradient(#fc4349, #960202);
  color: white;
  height: 100px;
  font-weight: bold;
  width: 200px;
  transform: rotate(45deg);
  text-align: center;
  z-index: 999;
}

.parisTourAdvBtnBox Button {
  color: white;
  display: block;
  margin: auto;
  line-height: normal;
}

.parisTourAdvBtnBox Button span:nth-child(1) {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 3px;
}

.parisTourAdvBtnBox Button span:nth-child(3) {
  letter-spacing: 5px;
  font-size: 14px;
  font-weight: bold;
}

.queryModalBox {
  width: 400px;
  box-sizing: border-box;
}

.customToolbar {
  min-height: auto !important;
  padding: 0;
}
/* .css-191lty2 {
  position: relative !important;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
} */

.customMainToolbar {
  background-color: white;
}
.main {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.sideImg {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 90%;
}
.sideImg img {
  border-radius: 50%;
  height: 200px;
  width: 200px;
}
.sideHeaderBox {
  margin-bottom: 15px;
  position: relative;
  text-align: center;
  z-index: 2;
}
.sideHeaderBox:before {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  content: "";
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: -1;
}
.sideInnerBox {
  align-items: center;
  background-color: #fcfcfc;
  color: #2c3e50;
  display: inline-flex;
  font-size: 1.1rem;
  justify-content: center;
  padding: 10px 0;
  text-transform: uppercase;
}
.testimonial {
  background: rgb(44 62 80 / 5%);
  border-radius: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  height: 626px;
  padding: 30px;
}
.owl-carousel .owl-item img {
  display: block;
}
.testimonial img {
  border-radius: 50%;
  height: 100px;
  width: 100px !important;
}
.testimonial .name {
  color: #2c3e50;
  font-size: 20px;
  margin: 20px 0;
  text-transform: uppercase;
}

.tabLink {
  color: white;
  text-decoration: none;
}
.headIng {
  color: #000;
  font-size: 30px;
  padding-left: 40px;
}
.headIngPhone {
  display: none;
}

#menu-appbar .tabLink {
  color: #2c3e50;
  text-decoration: none;
}

.navTab {
  opacity: 1 !important;
}

.mobileView_tabList .tabLink {
  color: #2c3e50;
  font-size: 12px;
}

.navTab:hover {
  color: #fff;
}

.css-k7y545-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
  text-shadow: 0px 0px 0px #fff;
}
.navbar_querySubmitBtn:hover {
  background-color: #2c3e50 !important;
}
/* HEADER END */

/* BANNER SECTION */

/* CSS */
.button-50 {
  max-height: 30px;
  appearance: button;
  background-color: #2c3e50;
  background-image: none;
  border: 1px solid #2c3e50;
  border-radius: 4px;
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 5px 20px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  transition: 0.3s;
}

.button-50:focus {
  text-decoration: none;
}

.button-50:hover {
  text-decoration: none;
}

.button-50:active {
  box-shadow: rgba(0, 0, 0, 0.125) 0 3px 5px inset;
  outline: 0;
}

.button-50:not([disabled]):active {
  box-shadow: #fff 0px 0px 0 0, #000 0px 0px 0 1px;
  transform: translate(2px, 2px);
}

/* terms and condition page start */
.conditionsList > li h3 {
  margin-top: 30px;
  text-transform: capitalize;
  letter-spacing: 2px;
  color: #2c3e50;
}
.conditionsList li p {
  font-size: 1.3em;
  font-weight: 200;
  color: #2c3e50;
  text-align: justify;
}
.conditionsList li ol,
.conditionsList li ul {
  margin-bottom: 15px;
}

.conditionsList li table.chargesTable {
  margin-bottom: 15px;
}
.conditionsList li table.chargesTable tr td {
  font-size: 1.1em;
  padding: 3px 20px;
  font-weight: 200;
  color: #2c3e50;
}

.conditionsList li ol li,
.conditionsList li ul li {
  font-size: 1.1em;
  margin-left: 15px;
  font-weight: 200;
  color: #2c3e50;
  text-align: justify;
}
.documentsTabsBox {
  background-color: white;

  white-space: nowrap;
}

.documentsTabsBox ul li a {
  text-decoration: none;
}
.lIne {
  padding-left: 50px;
}
.headImg {
  color: #000;
  font-size: 30px;
  padding-left: 40px;
}

.privacyPolicyContent {
  max-width: 75%;
  margin-right: auto;
  padding: 15px;
}

.documentsTabs li {
  list-style: none;
  margin: 10px;
  padding: 5px 10px;
  width: clac(100% - 10px);
  background-color: #f1f1f1;
}
.contentListBox ul li {
  list-style: none;
  margin: 10px;
  padding: 5px 10px;
  width: clac(100% - 10px);
  background-color: #fafafa;
}

.contentListBox ul li a {
  font-size: 1em;
  font-weight: 200;
  color: #2c3e50;
  text-decoration: none;
}

.documentsTabs li a {
  font-size: 1.3em;

  font-weight: 200;
  color: #2c3e50;
}

.conditionsListContainer {
  background-color: white;
  border: 1px solid rgba(43, 61, 79, 0.2);
  border-top: 5px solid #2c3e50;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
}

ul.conditionsList {
  list-style: none;
}
ul.conditionsList li {
  margin-bottom: 10px;
}
/* terms and conditions page end */

@media (min-width: 768px) {
  .button-50 {
    padding: 12px 50px;
  }
}

.tourAdvertisingBox {
  position: relative;
  background-image: url("https://memoriesmakeredu.com/images/graphics/wave (4).png");
  border-top: 80px solid rgba(252, 67, 73, 0.9);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 120%;
  min-height: 130px;
  margin-top: -4px;
}

.tourAdvertisingBox .tourheader {
  color: white;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 100;
  margin-top: -60px;
  text-align: center;
}
.tourAdvertisingBox .tourheader span {
  display: block;
}

.tourAdvertisingBox .tourheader span:nth-child(1) {
  font-weight: 500;
  font-size: 24px;
}

.tourAdvertisingBox .tourheader span:nth-child(2) {
  font-size: 18px;
  text-transform: lowercase;
  font-style: italic;
}

.tourAdvertisingBox .tourheader span:nth-child(3) {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 500;
  font-style: italic;
}

/*.tourAdvertisingBox:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 40%;
  width: 100%;
  background-color: #fc4349;
}*/

.homeBannerSection {
  position: relative;
}

.homeBannerQuote_carousel {
  max-width: 300px;
}

.bannerQuoteHeader {
  font-weight: bold;
  text-transform: uppercase;
  color: #fc4349;
  font-size: 42px;
}

.homeBannerQuote {
  position: absolute;
  top: 40%;
  min-height: 20%;
  transform: translateY(-55%);
  left: 8%;
  color: white;
}

.bannerQuoteContent {
  font-size: 40px;
  /* font-style: italic; */
  font-family: sans-serif;
  letter-spacing: 10px;
  /* max-width: 400px; */
}

.homeBannerPhotosBox {
  border: 1px solid red;
  position: absolute;
  right: 0;
  min-height: 300px;
  min-width: 400px;
  top: 10%;
}

/* PRIORITIES START */

.phoneViewPriorityTxt {
  background-color: white;
  width: 100%;
  font-size: 11px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: bold;
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
  color: #2c3e50;
}

.phoneViewPriorityTxt .iconBox .svgIconBox {
  height: 24px;
  width: 28px;
  /* border:1px solid red; */
}

/* .phoneViewPriorityTxt .iconBox .svgIconBox svg{
position: absolute;
top: 0;left: 0;z-index: 999; height:24px;
} */

.phoneViewPriorityTxt .iconBox {
  text-align: right;
  padding-right: 5px;
  max-width: 35%;
}

.priorityTextBox {
  max-width: 65%;
  height: 24px;
  display: flex;
  align-items: center;
}

.phoneViewPriorityTxt .healthIcon {
  fill: #fc4349;
}
.phoneViewPriorityTxt .foodIcon {
  fill: #1eaf32;
}
.phoneViewPriorityTxt .comfortableIcon {
  fill: #1fa2ff;
}
.phoneViewPriorityTxt .itineraryIcon {
  fill: #36d1dc;
}
.phoneViewPriorityTxt .mobfriendlyIcon {
  fill: #fc00ff;
}
.phoneViewPriorityTxt .ANOTHERIcon {
  fill: #f09819;
}
.priorityBox {
  border: 1px solid rgba(153, 153, 153, 0.3);
  background-color: rgba(44, 62, 80, 0.05);
  border-radius: 5px;
  height: 100%;
}

.priorityImg {
  padding: 0 20%;
}

.priorityTitle h3 {
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: #2c3e50;
}
/* PRIORITIES END */

/* RECENT TOURS START */

.headerBox {
  position: relative;
  z-index: 2;
  text-align: center;
  margin-bottom: 15px;
}
.eduHeaderBox {
  position: relative;
  z-index: 2;
  text-align: center;
  margin-bottom: 15px;
}

.headerBox:before {
  content: "";
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 50%;
  left: 0;
  z-index: -1;
}

.headerBox .innerBox {
  background-color: #fcfcfc;
  padding: 10px 0;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #2c3e50;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.eduHeaderBox .eduInnerBox {
  background-color: #fcfcfc;
  padding: 10px 0;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #2c3e50;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.boldText {
  font-weight: bold;
}
.liteText {
  font-weight: normal;
  opacity: 0.5;
}

/* RECENT TOURS END */

/* EDUCATIONAL TOURS START */

#educationalSection .owl-nav,
#liveVirtualSection .owl-nav {
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
  right: 10px;
  top: -60px;
  position: absolute;
  width: 100px;
  height: 40px;
  text-align: right;
}
#educationalSection .owl-nav .owl-prev,
#liveVirtualSection .owl-nav .owl-prev {
  position: absolute;
  top: 6px;
  text-align: center;
  vertical-align: middle;
  left: 15px;
  width: 20%;
  height: 60%;
}
#educationalSection .owl-nav .owl-next,
#liveVirtualSection .owl-nav .owl-next {
  position: absolute;
  top: 6px;
  text-align: center;
  vertical-align: middle;
  right: 15px;
  width: 20%;
  height: 60%;
}
#educationalSection .owl-nav .owl-prev:hover,
#liveVirtualSection .owl-nav .owl-prev:hover {
  background-color: transparent;
  color: black;
}
#educationalSection .owl-nav .owl-next:hover,
#liveVirtualSection .owl-nav .owl-next:hover {
  background-color: transparent;
  color: black;
}
#educationalSection .owl-nav .owl-prev span,
#educationalSection .owl-nav .owl-next span,
#liveVirtualSection .owl-nav .owl-prev span,
#liveVirtualSection .owl-nav .owl-next span {
  font-size: 40px;
  line-height: 10px;
}

.educationalTours_header,
.liveTours_header {
  font-weight: 400;
  margin-bottom: 15px;
  font-size: 1.4rem;
}

.intEduTourBox {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
}

.intEduTourBox .imgBox img {
  height: 200px;
}
.intEduTourBox {
  position: relative;
}
.intEduTourBox .titleBox {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 4px 10px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #2c3e50;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(255, 166, 166, 0) 100%
  );
  width: 100%;
  box-sizing: border-box;
  height: 50%;
  color: white;
}

.intEduTourBox .titleBox .subTitle {
  font-weight: 500;
  font-size: 10px;
}

/* EDUCATIONAL TOURS END */

/* LIVE VIRTUAL SECTION START */

.liveTag {
  background-color: #fc4349;
  border-radius: 2px;
  padding: 0 5px;
  color: white;
  font-weight: 500;
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 12px;
}

/* .liveVirtualSection{
        min-height: 450px;
        background-image: url('../public/images/bg/bg-gradient.png');
        background-size: cover;
    } */

/* LIVE VIRTUAL SECTION END */

/* BLOG SECTION START */

.cardBlog {
  background-size: cover;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: 0.4s;
}

.cardBlog .shareBtns {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: white;
}

.cardBlog .shareBtns a,
.cardBlog .shareBtns .copyIconBox {
  color: white;
  padding: 3px;
  vertical-align: baseline;
}

.cardBlog .blogHead {
  color: white;
  font-size: 24px;
}

.cardBlog .blogHead h3 {
  font-weight: 100;
}

.blogintro {
  color: white;
  font-weight: 500;
}

.blogTxt {
  padding: 8px;
  transition: 0.4s;
  padding-top: 50px;
  margin-bottom: -30%;
  background: linear-gradient(to top, #2c3e50, transparent);
}

.blogTxt .readmorelink {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;
  font-size: 12px;
  color: white;
}
.blogTxt .readmorelink a {
  font-weight: 500;
  color: white;
  text-decoration: none;
  transition: 0.2s;
}
.blogTxt .readmorelink a:hover {
  text-decoration: none;
}

.blogTxt .readmorelink .rightIcon {
  transform: rotate(90deg);
  height: 0;
  width: auto;
}

.blogTxt .readmorelink a:hover ~ .rightIcon {
  height: 20px;
}
.cardBlog .blogTxt {
  margin-bottom: 0;
}

/* .cardBlog:hover .blogTxt{
	transition: .4s;
	margin-bottom: 0;
} */

/* BLOG SECTION END */

/* HAPPY FACES SECTION START */

.maintestimonialBox {
  width: 100%;
  position: relative;
  margin: auto;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.032);
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 40px 5px 5px 5px;
  max-width: 380px;
}

.testimonialContent .testimonialName {
  text-align: right;
  background-color: #2c4350;
  color: white;
  line-height: 30px;
  padding-right: 15px;
  border-radius: 5px;
  width: calc(100% - 65px);
  left: 50px;
  position: absolute;
  top: 0;
  z-index: -1;
}

.testimonialContent img {
  height: 100px;
  width: 100px !important;
  margin-right: 8px;
  border: 1px solid #2c3e50;
  outline: 1px solid #fc4349;
  outline-offset: 4px;
  border-radius: 50%;
  float: left;
}

.testimonialText {
  min-height: 350px;
  text-indent: 10px;
  padding: 40px 15px 10px 15px;
  text-align: justify;
}

/* HAPPY FACES SECTION END */

/*NEW FEEDBACK SECTION START*/
.feedback-face {
  background-color: white;
  position: relative;

  text-align: center;
  border: 2px solid #2c3e50;
  padding: 15px;
  /* padding-bottom: 40px; */
  padding-top: 15px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}

.feedbackboxnew {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.02);

  padding: 15px;
  padding-top: 25px;
  margin-bottom: 50px;
  padding-right: 25px;
}
/* .feedbackcontent{
    padding-left: 50px;
  } */
.feedbackcarouselnew .item {
  padding: 15px;
}

.happyfacesTxt {
  font-size: 140px;
  line-height: 130px;
  font-weight: bold;
  padding-left: 40px;
}

.happyfacesTxt .text-happy {
  color: transparent;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.5pt;
  -webkit-text-stroke-color: #2c3e50;
}
.happyfacesTxt .text-faces {
  color: #fc4349;
  display: block;
  padding-left: 140px;
  color: transparent;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.5pt;
  -webkit-text-stroke-color: #fc4349;
}

/* .feedback-face:after{
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    height: 110%;
    width: 80%;
    border-radius: 10px;
    background-color: #fc4349;
    z-index: -1;
    filter: contrast(90%);
    
  } */

.feedbackheader {
  border-top: 4px solid #2c3e50;
  text-align: right;
}

.feedbackheaderTxt {
  font-weight: 100;
  font-size: 28px;
  color: white;
  display: block;
}
.feedback-name {
  font-style: italic;
  color: #fc4349;
  font-size: 16px;
  text-align: right;
  display: flex;
  justify-content: space-between;
}
.feedbackcontent {
  position: relative;
  letter-spacing: 2px;
  color: #2c3e50;
  /* border:1px solid #fff; */
  border-radius: 10px;
  font-size: 20px;
  font-weight: lighter;
  font-style: italic;
  text-align: center;
  padding: 10px;
}
/* .feedbackcontent:before{
    content: '\f10d';
    font-family: 'FontAwesome';
    font-size: 34px;
    position: absolute;
    top: -15px;
    left: 10px;  
    height: 40px;
    width: 40px;  
    color: rgba(0, 0, 0, .2);
  }
  
  .feedbackcontent:after{
    content: '\f10e';
    font-family:FontAwesome;
    font-size: 34px;
    color: rgba(0, 0, 0, .2);
    position: absolute;
    bottom: -15px;
    right: -15px;  
    height: 40px;
    width: 40px;  
  } */

.feedback-face img {
  width: 80%;
  border-radius: 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}
/*NEW FEEDBACK SECTION END*/

/*FEEDBACK ICON START*/
.feedbackStar {
  transform: rotate(-90deg);
}

.BadgeContainer__Inner-sc-pa6z2-0 {
  left: -5px !important;
  bottom: -10px !important;
}

.feedbackIcon {
  position: relative;
  top: -68px;
  right: -105px;
  z-index: 999;
  transform: rotate(90deg) translateY(100%);
  transition: 0.3s;
}

.feedbackIcon a .smileIcon {
  transform: rotate(-90deg);
  font-size: 18px;
  filter: contrast(150%);
  margin-bottom: -4px;
  color: #fc4349;
}
.feedbackIcon a {
  text-decoration: none;
  display: flex;
  align-items: center;
  z-index: 999;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #2c3e50;
  transition: 0.3s;
  border: 1px solid #2c3e50;
  border-left: 3px solid #fc4349;
  border-right: 3px solid #fc4349;
  background-color: white;
  padding: 3px 8px;
}
.feedbackIcon a img {
  filter: contrast(150%);
}
.feedbackIcon:hover {
  transform: rotate(90deg) translateY(100%) scale(1.1);
}

.fixedContactIcons {
  position: fixed;
  top: 52%;
  right: -5px;
  padding: 15px 0;
  z-index: 999;
}

.fixedContactIcons a {
  text-decoration: none;
}

/*WHATSAPP CHAT ICON START*/
.whatsappChatIcon {
  max-width: 120px;
  transform: translateX(67%);
  margin-top: 10px;
  margin-left: auto;
  transition: 0.4s;
}

.whatsappChatIcon:hover {
  transform: translateY(0);
}
.whatsappChatIcon .innerBox {
  background-color: white;
  padding: 5px;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  display: flex;
}

.whatsappTxt {
  font-weight: bold;
  color: #2c3e50;
}

.whatsappChatIcon .WhatsappIcon {
  /*position: fixed;
  bottom: 40px;
  right: 20px;*/
  margin-right: 14px;
  color: #28a44a;
  /*background-color: #28a44a;*/
  z-index: 999;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.whatsappChatIcon i {
  color: #28a44a;
}
/*WHATSAPP CHAT ICON END*/

/*MESSAGE CHAT ICON START*/
.messageChatIcon {
  max-width: 160px;
  transform: translateX(73%);
  margin-top: 10px;
  margin-left: auto;
  transition: 0.4s;
}

.messageChatIcon:hover {
  transform: translateY(0);
}
.messageChatIcon .innerBox {
  background-color: white;
  padding: 5px;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  display: flex;
}

.messageTxt {
  font-weight: bold;
  color: #2c3e50;
}

.messageChatIcon .messageIcon {
  /*position: fixed;
  bottom: 40px;
  right: 20px;*/
  margin-right: 14px;
  color: #28a44a;
  /*background-color: #28a44a;*/
  z-index: 999;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.messageChatIcon i {
  color: #f3b711;
}
/*MESSAGE CHAT ICON END*/

/*MESSAGE CHAT ICON START*/
.callChatIcon {
  max-width: 140px;
  transform: translateX(72%);
  margin-top: 10px;
  margin-left: auto;
  transition: 0.4s;
}

.callChatIcon:hover {
  transform: translateY(0);
}
.callChatIcon .innerBox {
  background-color: white;
  padding: 5px;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  display: flex;
}

.callTxt {
  font-weight: bold;
  color: #2c3e50;
}

.callChatIcon .callIcon {
  margin-left: 5px;
  margin-right: 15px;

  z-index: 999;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.callChatIcon i {
  color: #fc4349;
}
/*MESSAGE CHAT ICON END*/

.feedbackcarouselnew .owl-item {
  opacity: 0.5;
  transform: scale(0.8) !important;
  transition: 1s;
}
.feedbackcarouselnew .owl-item.active {
  opacity: 1 !important;
  transform: scale(1) !important;
  transition: 1s;
}
/*FEEDBACK ICON END*/

/* PARTNERS SECTION START */

.partnerLogo {
  border: 1px solid #ccc;
  padding: 20px;
}

/* PARTNERS SECTION END */

/* FOOTER SECTION START */

.phoneview_footerTabs {
  padding: 0;
  list-style: none;
  font-weight: 100;
}

.phoneview_footerTabs li {
  padding: 10px 5px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.32);
}

.phoneview_footerTabs li a {
  display: inline-block;
  width: 100%;
}
.footercontainer {
  padding-bottom: 30px;
}
.footerTabs {
  padding-left: 0px;
}

.footerTab {
  list-style: none;
  margin-top: 10px;
  font-size: 12px;
}

.footerTab {
  font-weight: normal;
}

.footerTab a {
  color: #fff;
  letter-spacing: 2px;
  font-size: 12px;
  text-decoration: none;
}

.footerTabs li:first-child {
  font-weight: bold;
  font-size: 16px;
}

/* mobile view footer */

.mobFooter-address .socialIcon {
  display: inline-block;
}

.mobFooter-address .locationIcon,
.mobFooter-address .mailIcon,
.mobFooter-address .contactIcon {
  border: 1px solid #888;
  /* padding: 15px 10px; */
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  font-size: 12px;
  color: white;
  font-weight: bold;
  margin-bottom: 5px;
}

.mobFooter-address .mailIcon,
.mobFooter-address .contactIcon {
  margin-left: 5px;
}

.mobFooter-address .instaIcon,
.mobFooter-address .fbIcon,
.mobFooter-address .twitIcon {
  margin-bottom: -2px;
}

.pageTabsBox .pageTabs,
.pageTabsBox .expandmoreIcon {
  background-color: transparent !important;
  color: white;
  font-size: 12px;
}
.pageTabsBox .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/* SUBSCRIPTION INPUT & BUTTON */

.footerSubscription > h4 {
  font-size: 14px;
  margin: 10px 0 5px 0;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
/* .footerSubscription{
  border:1px solid red;
} */
.footerSubscription .email_box {
  padding: 10px;
  border: none;
  font-weight: bold;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: block;
  min-width: 200px;
}

.footerSubscription .email_box::placeholder {
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
}

.subscribeBtn {
  border: none;
  outline: none;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  background-color: transparent;
  border-radius: 6px;
  transition: 0.2s;
  display: block;
  margin-left: auto;
  border: 1px solid white;
}
.subscribeBtn:hover {
  color: #fc4349;
  background-color: white;
  border: 1px solid #2c3e50;
}
/* SUBSCRIPTION INPUT & BUTTON */

/* FOOTER SECTION END */

/* //////////////////////////ABOUT PAGE///////////////////////////////// */

.aboutContent,
.whatWeDoContent {
  margin-bottom: 60px;
  font-size: 1.3em;
  font-weight: 200;
  color: #2c3e50;
  text-align: justify;
}

.quoteSection {
  margin-bottom: 180px;
  margin-top: 120px;
  background-color: #e8e8e8;
  font-size: 20px;
  padding: 5px;
}
.quote001 {
  font-family: "Courgette", cursive;
}
.quote001Writter {
  font-size: 14px;
}

/* /////////////////////////////CONTACT PAGE/////////////////////////////////// */

/* faq */

.faqAccordian {
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 2%) !important;
}
.faqAccordian::before {
  display: none !important;
}

.faqAccordian .faqListHeader {
  font-weight: bold;
  color: rgba(44, 62, 80, 0.7);
  display: flex;
  align-items: flex-start;
}

.faqAccordian .faqListAnswer {
  font-size: 1.2rem;
  font-weight: 300;
  color: rgba(44, 62, 80, 0.7);
}

.faqAccordian .faqListHeader .faqListIcon {
  font-size: 22px;
}

.faqAccordian .faqListAnswer .safteyRules {
  font-size: 16px;

  list-style: none;
}
.faqAccordian .faqListAnswer .safteyRules li {
  display: inline-block;
  background-color: #2c3e50;
  font-size: 14px;
  color: white;
  padding: 0px 8px;
  margin: 2px 4px;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.faqAccordian .faqListHeader:hover {
  color: #fc4349;
}

/* style={{ marginTop:'10px', marginBottom:'10px', boxShadow: '0 0 10px 5px rgb(0 0 0 / 2%)'}} */
/*  */

.pageBanner {
  min-height: 300px;
}

.pageBanner {
  z-index: 9;
  background: linear-gradient(to top right, #101c27, #184775, #2c3e50);
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}

@keyframes bgmove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bannerHeader {
  font-size: 6rem;
  color: white;
  line-height: 7.5rem;
}

.bannerSubHeader {
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  display: flex;
  justify-content: space-around;
}
.bannerSubHeader_ahmedabad {
  font-size: 16px;
  text-transform: uppercase;
  color: #765959;
  display: flex;
  justify-content: space-around;
}

.contactInfo .info {
  padding: 20px 18px;
  color: #47536e;
  height: 275px;
  text-align: center;
  transition: 0.5s !important;
}

.contactSocial_icon {
  font-size: 34px;
  color: #2c3e50;
}

.contactInfo .info h4 {
  padding: 0;
  color: #fc4349;
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.contactInfo .info:hover {
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1);
}
.contactInfo .info div {
  transition: 0.4s !important;
}
.contactInfo .info:hover div {
  transform: translateY(-10px);
}

/* .contactInfo{
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../public/images/bg/taj-bg.jpg');
  } */

.contactInfo Button {
  background: #fff;
  border: 2px solid #2c3e50;
  padding: 5px 40px;
  color: #2c3e50;
  transition: 0.6s;
  border-radius: 50px;
  margin-top: 15px;
}

/* FOUNDER START */

.chairmanPhoto img {
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.2);
  border-bottom: none;
  max-width: 480px;
}

.chairmanNameBox {
  margin: auto;
  margin-top: -6px;
  padding: 10px;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
  /* background: linear-gradient(to top, #141E30, #243B55); */
  text-align: center;
  background: linear-gradient(to top, #141e30, #243b55 70.71%),
    linear-gradient(to top, #243b55, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(to top, rgba(0, 0, 255, 0.01), rgba(0, 0, 255, 0) 70.71%);
  /* filter: contrast(1.2); */
}

.chairmanName {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
}

.chairMancompanyName {
  font-size: 16px;
  letter-spacing: 3px;
  word-spacing: 4px;
  color: white;
}
.aboutChairman {
  font-size: 0.9em;
  letter-spacing: 2px;
  font-weight: 200;
  text-align: justify;
  color: #03060c !important;
}

/* FOUNDER END */

.parisTourItineraryBtn2 .MuiSpeedDial-actions,
.parisTourItineraryBtn .MuiSpeedDial-actions {
  -webkit-flex-direction: row !important;
  flex-direction: row !important;
  position: absolute;
  top: 75px;
}

.parisTourItineraryBtn2 .MuiSpeedDial-actions button,
.parisTourItineraryBtn .MuiSpeedDial-actions button {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.parisTourItineraryBtn {
  padding: 20px 10px 0 10px;
  width: 147px;
}

.parisTourItineraryBtn2 {
  padding: 20px 10px 0 10px;
  width: 80px;
}

.parisTourItineraryBtn2 button.MuiSpeedDial-fab,
.parisTourItineraryBtn button.MuiSpeedDial-fab {
  min-height: 0 !important;
  height: 0 !important;
}

.parisTourItineraryBtn2 button.MuiButtonBase-root,
.parisTourItineraryBtn button.MuiButtonBase-root {
  width: 30px;
  height: 30px;
  min-height: auto;
  line-height: 30px;
  vertical-align: middle;
  color: #fff;
  background-color: #2c3e50;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.02);
  outline: 1px solid rgba(0, 0, 0, 0.04);
}

.shareButtonSpeedDial button {
  width: 30px;
  height: 30px;
  min-height: auto;
  line-height: 30px;
  vertical-align: middle;
  color: #2c3e50;
  background-color: white;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.02);
  outline: 1px solid rgba(0, 0, 0, 0.04);
}

.shareButtonSpeedDial button:hover {
  background-color: white;
  color: #2c3e50;
}

.shareButtonSpeedDial button svg {
  font-size: 16px;
}

.shareButtonSpeedDial.css-1r2qxv6-MuiSpeedDial-root .MuiSpeedDial-actions {
  margin-right: -40px;
}

.blogHeaderBox .mainHeader {
  font-size: 24px;
}

.blogHeaderBox .subHeader {
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
}

.blogUploadedDate {
  color: #fc4349;
  font-weight: bold;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.blogContent p {
  font-size: 1.4rem;
  font-weight: 100;
  color: #2c3e50;
  text-align: justify;
  line-height: 1.8rem;
}

.cardImgBox {
  position: relative;
}
.shareBox {
  position: absolute;
  top: -5px;
  right: -10px;
  transform: scale(0.8);
}

.blogCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardImgBox .css-46bh2p-MuiCardContent-root,
.cardImgBox .css-46bh2p-MuiCardContent-root:last-child {
  padding: 5px;
  padding-bottom: 0;
  padding-top: 0;
}

.cardImgBox .css-z2eky3-MuiTypography-root {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.02);
  margin: 0;
  padding: 5px;
}
.instaImgBox img {
  max-width: 100%;
  max-height: 100%;
}
.instaImgBox {
  width: 295px;
  height: 200px;
}

/* ////////////ITINERARY/////////////// */

.itinerarySection {
  /*border: 1px solid red;*/
  position: sticky;
  top: 110px;
  padding: 5px 0;
  background-color: white;
  z-index: 888;
}

.tourName {
  color: #2c3e50;
  margin: 0;
}

.tourName h3 {
  font-size: 18px;
  color: rgba(44, 62, 80, 0.5);
}

.placeName {
  color: #2c3e50;
  font-weight: bold;
  font-size: 30px;
  text-transform: uppercase;
}

.tourDetails span.days {
  color: #fff;
  display: inline-block;
  padding: 1px 4px;
  border-radius: 3px;
  background-color: #fc4349;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
}

.tourDetails span.package {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  display: inline-block;
  padding: 1px 4px;
  border-radius: 3px;
  background-color: #2c3e50;
}

.itinerarayGalleryCarousel .imgBox {
  max-height: 300px;
  display: flex;
  align-items: flex-start;
}

.tourDescription {
  font-size: 1.1em;
  font-weight: 200;
  color: #2c3e50;
  text-align: justify;
}

.dayHeaderBox {
  background-color: #f8f6f6;
  padding: 10px 15px;
  display: flex;
  position: sticky;
  top: 205px;
  z-index: 2;
  justify-content: space-between;
}

.dayOneHeaderTxt {
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.dayOneHeaderTxt .place {
  color: #fc4349;
}

.dayContent {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.03);
}

.dayContent p {
  font-size: 1.1em;
  letter-spacing: 1px;
  font-weight: 200;
  color: #2c3e50;
  text-align: justify;
}

.todoListHeader {
  margin-top: 15px;
  font-weight: bold;
  font-size: 1.3em;
  color: #2c3e50;
}

.dayContent .todoList ul {
  list-style: none;
  padding: 0;
}
.dayContent .todoList ul li {
  margin-bottom: 5px;
  letter-spacing: 1px;
  font-size: 14px;
}

.checkCircle {
  color: #fc4349;
  vertical-align: middle;
}

.daysNavigationBox {
  position: sticky;
  top: 205px;
}

.dayHeader {
  letter-spacing: 1px;
  font-weight: normal;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 10px 15px;
  padding-left: 5px;
  position: relative;
}

.dayHeader .calendarIcon {
  vertical-align: text-bottom;
  color: #2c3e50;
}

.dayHeader:before {
  content: "";
  position: absolute;
  right: 0;
  top: 15%;
  height: 70%;
  border-right: 1px solid #fc4349;
  opacity: 0.5;
}
.tourDateNavigations {
  list-style: none;
}
.tourDateNavigations a {
  text-decoration: none;
  font-weight: bold;
  color: #2c3e50;
  border: none;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  width: 100%;
}

.tourDateNavigations li:hover {
  background: linear-gradient(45deg, #2e3192, #1bffff);
  color: white;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
  border: none;
}

.tourDateNavigations li:hover a {
  color: white;
}

.tourDateNavigations li {
  padding: 5px 0 5px 25px;
}

.inclusiveCard {
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 11%);
  border: solid 1px #e7e7e7;
  background-color: #ffffff;
  position: sticky;
  position: -webkit-sticky;
  top: 130px;
  max-width: 350px;
  margin: auto;
  z-index: 888;
  margin-bottom: 15px;
}

.inclusiveCardTwo {
  width: 168px;
  position: static !important;
  display: inline-block;
}
.inclusiveCardThree {
  width: 168px;
  position: static !important;
  margin-left: 4px;
  display: inline-block;
}

.inclusiveCardTwoWrapper {
  position: sticky;
  top: 280px;
  background-color: #f2f2f2;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  max-width: 350px;
  margin: auto;
}

.inclusiveCardFour {
  top: 430px;
  z-index: 4;
}

.inclusiveCardFive {
  top: 580px;
  margin-bottom: 40px;
  z-index: 5;
}

.inclusiveCard .inclusiveHeader,
.inclusiveCardTwo .inclusiveHeader,
.inclusiveCardThree .inclusiveHeader,
.customizeTourBtnBox button {
  color: white;
  font-size: 20px;
  line-height: 28px;
  padding: 3px 10px;
  letter-spacing: 1px;
  font-weight: bold;
}

.inclusiveCardOne .inclusiveHeader {
  background: linear-gradient(45deg, #ff0078, #f6efa7);
}
.inclusiveCardTwo .inclusiveHeader {
  background: linear-gradient(45deg, #9600ff, #aebaf8);
}
.inclusiveCardThree .inclusiveHeader {
  background: linear-gradient(45deg, #08a583, #c1fcd3);
}
.inclusiveCardFour .inclusiveHeader {
  background: linear-gradient(45deg, #369ae6, #09dae6);
}
.inclusiveCardFive .inclusiveHeader {
  background: linear-gradient(45deg, #d17649, #eea47fff);
}

.inclusiveContent {
  padding: 5px 10px 15px 10px;
}
.inclusiveContent ul {
  margin-top: 10px;
  display: inline-block;
  padding-left: 0;
  vertical-align: top;
  list-style: none;
}
.inclusiveContent ul li {
  font-size: 14px;
  color: #2c3e50;
  padding: 5px 0;
}

.termsConditionPara {
  font-size: 12px;
  height: 100%;
  display: flex;
  align-items: center;
}

.franceTourSection {
  background-color: rgba(44, 62, 80, 0.05);
  border-radius: 10px;
  padding: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
  box-sizing: border-box;
}

.introVideo {
  margin-top: 100px;
  border-radius: 15px;
  position: relative;
}

.monumentBox {
  width: 100%;
  display: flex;
  border-radius: 5px;
  align-items: center;
}

.monumentBox1 .monumentName {
  font-weight: bold;
  color: #fc4349;
  font-size: 18px;
  padding-left: 15px;
}

.monumentBox2 .monumentName {
  font-weight: bold;
  color: #0ba7a7;
  font-size: 18px;
  padding-left: 15px;
}

.monumentBox3 .monumentName {
  font-weight: bold;
  color: #726cc5;
  font-size: 18px;
  padding-left: 15px;
}

.monumentBox1 .imgBox {
  height: 50px;
  width: 50px;
  background-image: url("https://memoriesmakeredu.com/images/itinerary/paris/arc-de-triomphe-paris.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.monumentBox2 .imgBox {
  height: 50px;
  width: 50px;
  background-image: url("https://memoriesmakeredu.com/images/itinerary/paris/eiffil-tower-paris.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.monumentBox3 .imgBox {
  height: 50px;
  width: 50px;
  background-image: url("https://memoriesmakeredu.com/images/itinerary/paris/louvre-paris.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* .monumentBox1{background: linear-gradient(90deg, #FFA6A6 0%, rgba(255, 166, 166, 0) 100%);}
.monumentBox2{background: linear-gradient(90deg, rgb(23, 207, 207, .5) 0%, rgba(62, 123, 250, 0.00520833) 99.99%, rgba(62, 123, 250, 0) 100%)}
.monumentBox3{background: linear-gradient(90deg, rgb(114, 108, 197, .5) 0%, rgba(255, 166, 166, 0) 100%);} */

.monumentBox1 {
  background: linear-gradient(90deg, #ffa6a6 0%, rgba(255, 166, 166, 0) 100%);
}
.monumentBox2 {
  background: linear-gradient(
    90deg,
    rgb(23, 207, 207, 0.5) 0%,
    rgba(62, 123, 250, 0.00520833) 99.99%,
    rgba(62, 123, 250, 0) 100%
  );
}
.monumentBox3 {
  background: linear-gradient(
    90deg,
    rgba(94, 193, 223, 0.5) 0%,
    rgba(255, 166, 166, 0) 100%
  );
}

.introVideoBox .video-react .video-react-video,
.introVideoBox .video-react-control-bar {
  border-radius: 10px;
}

.introVideo:before {
  content: "";
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(5deg);
  height: 100%;
  border-radius: 10px;
  animation-duration: 10s;
  width: 100%;
  /* background-color: rgba(252, 67, 73, .5); */
  background-color: rgba(44, 62, 80, 0.5);
}

@keyframes animatevideo {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(5deg);
  }
}
.discFranceQuoteBox {
  position: relative;
}
.discFranceQuoteBox:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: -1;
  background-image: url("https://memoriesmakeredu.com/images/graphics/france-map.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.discFranceQuoteBox .letsText {
  font-size: 38px;
  line-height: 30px;
  font-weight: 100;
}

.discFranceQuoteBox .discoverText {
  font-size: 68px;
  line-height: 30px;
  font-weight: bold;
  color: #2c3e50;
}

.discFranceQuoteBox .franceText {
  font-size: 68px;
  font-weight: bold;
  color: #fc4349;
}

.franceTourEnrollBtn {
  border: 1px solid #fc4349 !important;
}

/* REGISTRATION FORM */
.registrationBox {
  margin-top: 20px;
  padding: 15px;

  border-radius: 10px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
}

.mailIcon {
  opacity: 0.7;
  margin-bottom: -2px;
}

.discriptionBox,
.videoBox {
  margin-top: 10px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
}

.discriptionBox {
  padding-bottom: 0;
}

.discriptionBox p {
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 100;
  color: rgba(0, 0, 0, 1);
}

.videoBox .ytAdVideo {
  width: 420px;
}
.videoBox iframe {
  border-radius: 10px;
}

.advertiseCarousel.owl-carousel .owl-item img {
  border-radius: 5px;
}
.advertiseBannerCarousel.owl-carousel .owl-item {
  border-radius: 5px;
  max-height: 200px;
  overflow: hidden;
}
.advertiseBannerCarousel.owl-carousel .item {
  position: relative;
}
.advertiseBannerCarousel.owl-carousel .item:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
}

.advertiseTags div {
  background-color: #2c3e50;
  color: white;
  border-radius: 3px;
  font-size: 12px;
  padding: 5px 5px;
  text-align: center;
  font-weight: bold;
}
.webVideoBox {
  position: relative;
}
/* .webVideoBox .owl-nav{box-shadow: 0 0 12px 3px rgba(0,0,0,.04);border-radius: 25px;right: 10px;top: -60px;position: absolute;width: 100px;height: 40px;text-align: right;} */

.webVideoBox .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  vertical-align: middle;
  left: -20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #2c3e50 !important;
}
.webVideoBox .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  vertical-align: middle;
  right: -20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #2c3e50 !important;
}
/* .webVideoBox .owl-nav .owl-next{position: absolute;top: 6px;text-align: center;vertical-align: middle;right: 15px;width: 20%;height: 60%;} */
.webVideoBox .owl-nav .owl-prev:hover {
  background-color: transparent;
  color: black;
}
.webVideoBox .owl-nav .owl-next:hover {
  background-color: transparent;
  color: black;
}
.webVideoBox .owl-nav .owl-prev span,
.webVideoBox .owl-nav .owl-next span {
  font-size: 40px;
  line-height: 40px;
  vertical-align: middle;
  color: white;
  transform: translateY(-5px);
}

.successRegistrationAlert,
.failedRegistrationAlert {
  border: none !important;
  outline: none !important;
  position: relative;
  border-radius: 10px;
  text-align: center;
  padding: 10px 0 0 0 !important;
  background-color: white;
}

.successAlertBox .css-sox5kk-MuiBackdrop-root {
  background-color: transparent !important;
  backdrop-filter: blur(3px);
}

.successRegistrationAlert .header,
.failedRegistrationAlert .header {
  color: white;
  display: block;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  line-height: normal;
}

.successRegistrationAlert .content,
.failedRegistrationAlert .content {
  color: #2c3e50;
  font-weight: bold;
  padding-bottom: 10px;
}

.failedRegistrationAlert .content {
  color: #fc4349;
  width: 100%;
  font-size: 24px;
  display: block;
  /* background: linear-gradient(45deg, black, transparent); */
  border-radius: 10px;
  background: #f3f3f3;
  font-weight: normal;
  padding: 15px 0;
  margin-top: 0;
}

.failedRegistrationAlert .header div {
  background: rgba(255, 0, 0, 0.2);
  max-width: 130px;
  margin: auto;
  height: 130px;
  border-radius: 50%;
}

.loadingBox {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999 !important;
  background-color: white;
}

.parisBtnBox {
  position: absolute;
  top: 100px;
  right: 40px;
  /* border: 4px solid red; */
  background-color: white;
  border-radius: 5px;
  height: 40px;
  width: 150px;
  z-index: 9999;
  overflow: hidden;
  box-sizing: border-box;
}

.parisBtnBox:before {
  content: "";
  height: 32px;
  width: 142px;
  position: absolute;
  top: 0;
  left: 0;
  border: 4px solid red;
}

/* .parisBtnBox .innerBox{
position: relative;
} */

/* .parisBtnBox:before{
  content: '';
  position: absolute;
  top: -30px;
  left: 30px;
  border: 4px solid red;
  background-color: white;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  z-index: 0;
} */

.parisBtnBox span:nth-child(1) {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  border-top: 0px solid white;
  border-right: 2px solid white;
  border-left: 8px solid white;
  border-bottom: 0px solid white;
  height: 4px;
  width: 3px;
  background-color: red;
  z-index: 0;
  animation-name: animateBoxtop;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  visibility: hidden;
}

@keyframes animateBoxtop {
  0% {
    top: 0px;
    right: 0;
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    top: 0px;
    right: 370%;
  }
}

.parisBtnBox span:nth-child(2) {
  content: "";
  position: absolute;
  bottom: 0;
  left: -8px;
  border-top: 0px solid white;
  border-right: 8px solid white;
  border-left: 2px solid white;
  border-bottom: 0px solid white;
  height: 4px;
  width: 3px;
  background-color: red;
  z-index: 0;
  animation-name: animateBoxbottom;
  animation-duration: 20s;
  animation-delay: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  visibility: hidden;
}

@keyframes animateBoxbottom {
  0% {
    bottom: 0px;
    left: -9px;
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    bottom: 0;
    left: 350%;
  }
}

.parisBtnBox span:nth-child(3) {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  border-top: 2px solid white;
  border-right: 0px solid white;
  border-left: 0px solid white;
  border-bottom: 8px solid white;
  height: 3px;
  width: 4px;
  background-color: red;
  z-index: 0;
  animation-name: animateBoxleft;
  animation-duration: 20s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  visibility: hidden;
}

@keyframes animateBoxleft {
  0% {
    top: 0px;
    left: 0px;
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    top: 310%;
    left: 0px;
  }
}

.parisBtnBox span:nth-child(4) {
  content: "";
  position: absolute;
  top: 100%;
  visibility: hidden;
  right: 0px;
  border-top: 8px solid white;
  border-right: 0px solid white;
  border-left: 0px solid white;
  border-bottom: 2px solid white;
  height: 3px;
  width: 4px;
  background-color: red;
  z-index: 0;
  animation-name: animateBoxright;
  animation-duration: 20s;
  animation-delay: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transition: 0.3s;
}

@keyframes animateBoxright {
  0% {
    top: 100%;
    right: 0px;
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
    top: -400%;
    right: 0px;
  }
}

/* PARIS BTN */
/*.parisbtnbox1{
  position: absolute;
  top: 6.3%;
  left: 200px;
  z-index: 9999;
}*/
.ctaBtn {
  color: #101832;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.075em;
  padding: 0.4em 1em;
  font-weight: bold;
  margin: auto 2em;
  position: relative;
  align-self: center;
  text-transform: uppercase;
  border: 3px #2c3e50 solid;
  border-image: linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  border-image-slice: 1 1 0 0;
  z-index: 1;
  box-shadow: -0.5em 0.5em rgba(16, 24, 50, 0);
  transform-origin: left bottom;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.bannerGraphics:before {
  content: "Students Edu Tour to Paris and Brussels";
  position: absolute;
  top: 0;
  display: flex;
  left: 0;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, transparent);
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 25px;
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-color: #2c3e50;
  text-decoration-style: double;
}

.registrationMainHeader {
  /*background: linear-gradient(45deg, #2c3e50, #8393a3);*/
  background-color: #2c3e50;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 100;
  color: white;
}

.ratingImage {
  width: 200px;
  position: absolute;
  right: 5px;
  top: 9px;
}

.ctaBtn:before,
.ctaBtn:after {
  border: 3px #2c3e50 solid;
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
}
.ctaBtn:before {
  border-image: linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  border-image-slice: 1 1 0 1;
  /* border:1px solid green; */
  left: -0.59em;
  top: 0.15em;
  width: 0.17px;
  height: 95%;
  transform: skewY(-45deg);
}
.ctaBtn:after {
  border-image: linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  border-image-slice: 1 1 1 0;
  bottom: -0.61em;
  right: 0.16em;
  width: 100%;
  height: 0.1px;
  transform: skewX(-45deg);
}
.ctaBtn:hover {
  background-color: white;
  background-size: 90%;
  transform: translate(0.5em, -0.5em);
  box-shadow: -1em 1em 0.15em rgba(16, 24, 50, 0.1);
}
.ctaBtn:hover:before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwOTdkZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -moz-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  background-image: -webkit-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  background-image: linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  height: calc(100% - 0.5em);
  border-image-slice: 1;
}
.ctaBtn:hover:after {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -moz-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  background-image: -webkit-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  background-image: linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  width: calc(100% - 0.13em);
  border-image-slice: 1;
}

.cta {
  /* background-color: #2c3e50; */
  /* background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA=='); */
  background-size: 100%;
  /* background-image: -moz-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  background-image: -webkit-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  
   */
  /* background-image: linear-gradient(45deg, #2c3e50 0%, #ff0000 100%); */
  border-image: linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);

  border-image-slice: 1;
  color: black;
}
.cta:before {
  border-image-slice: 1;
  /* background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwOTdkZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA=='); */
  background-size: 100%;
  background-image: -moz-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  background-image: -webkit-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  background-image: linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  left: -0.5em;
  top: 0.15px;
}
.cta:after {
  border-image-slice: 1;
  /* background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA=='); */
  background-size: 100%;
  background-image: -moz-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  background-image: -webkit-linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  background-image: linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  bottom: -0.5em;
  right: 0.15px;
}
.cta:hover {
  background: white;
  border-image: linear-gradient(45deg, #2c3e50 0%, #2c3e50 100%);
  border-image-slice: 1;
  color: #101832;
}
.cta:hover:before {
  height: 95%;
}
.cta:hover:after {
  width: 100%;
}

.bg {
  background: none;
  border: 3px solid #101832;
  color: #101832;
}
.bg:before,
.bg:after {
  background: #101832;
  border: 3px solid #101832;
}
.bg:hover {
  border-image: none;
}
.bg:hover:before,
.bg:hover:after {
  background: #101832;
}

/* PARIS BTN */

/*REGISTRATION FORM SUBMIT BTN*/
.bubbly-button {
  font-family: "Helvetica", "Arial", sans-serif;
  display: block;

  font-size: 1em;
  padding: 0.5em 1em;
  margin-left: auto;
  -webkit-appearance: none;
  appearance: none;
  background-color: #2c3e50;
  color: #fff;
  border-radius: 30px;
  outline: 1px solid #2c3e50;
  outline-offset: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 25px rgba(44, 62, 80, 0.5);
}
.bubbly-button:active {
  outline-offset: 1px;
}
.bubbly-button:before,
.bubbly-button:after {
  position: absolute;
  content: "";
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}
.bubbly-button:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, #2c3e50 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, #2c3e50 20%, transparent 30%),
    radial-gradient(circle, #2c3e50 20%, transparent 20%),
    radial-gradient(circle, #2c3e50 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #2c3e50 15%, transparent 20%),
    radial-gradient(circle, #2c3e50 20%, transparent 20%),
    radial-gradient(circle, #2c3e50 20%, transparent 20%),
    radial-gradient(circle, #2c3e50 20%, transparent 20%),
    radial-gradient(circle, #2c3e50 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
    10% 10%, 18% 18%;
}
.bubbly-button:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, #fc4349 20%, transparent 20%),
    radial-gradient(circle, #fc4349 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #fc4349 15%, transparent 20%),
    radial-gradient(circle, #fc4349 20%, transparent 20%),
    radial-gradient(circle, #fc4349 20%, transparent 20%),
    radial-gradient(circle, #fc4349 20%, transparent 20%),
    radial-gradient(circle, #fc4349 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}
.bubbly-button:active {
  transform: scale(0.9);
  background-color: #2c3e50;
  box-shadow: 0 2px 25px rgba(44, 62, 80, 0.2);
}
.bubbly-button.animate:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}
.bubbly-button.animate:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
/*REGISTRATION FORM SUBMIT BTN*/

@media (min-width: 425px) {
  .advertiseCarousel {
    display: block;
  }
  .webVideoBox {
    display: block;
  }

  .advertiseBannerParis {
    display: block;
  }

  .mobVideoBox .mmVideo {
    width: calc(33% - 10px) !important;
    height: 210px !important;
    display: inline-block;
    margin: 5px;
    overflow: hidden;
    border-radius: 10px;
  }
}

@media (max-width: 425px) {
  .ratingImage {
    width: 160px;
  }

  .mobVideoBox .mmVideo {
    width: 100% !important;
    height: 210px !important;
    display: block;
    margin: 0px;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 10px;
  }
  .advertiseBannerCarousel.owl-carousel .owl-item {
    border-radius: 5px;
    max-height: 170px;
    overflow: hidden;
  }
  .advertiseTags div {
    font-size: 12px;
    padding: 3px 5px;
    min-width: 27%;
  }
  .advertiseCarousel {
    display: none;
  }
  .advertiseBannerParis {
    display: none;
  }
  .webVideoBox {
    display: none;
  }
  .mobVideoBox {
    display: block;
  }

  .videoBox .ytAdVideo {
    width: 100%;
  }
  #educationalSection .owl-nav,
  #liveVirtualSection .owl-nav {
    transform: scale(0.8);
  }

  .franceTourSection {
    display: none;
  }

  .homeBannerQuote {
    top: 40%;
    min-height: 40%;
  }

  .homeBannerQuote_carousel {
    max-width: 150px;
  }

  .bannerQuoteHeader {
    font-size: 20px;
  }

  .bannerQuoteContent {
    font-size: 16px;
    letter-spacing: 1px;
  }

  .cardBlog .blogHead {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .blogTxt {
    margin-bottom: -130px;
    padding-bottom: 10px;
  }
  .queryModalBox {
    width: calc(100% - 15px);
  }

  .bannerSubHeader {
    font-size: 10px;
  }

  .customContainer {
    margin-top: 40px;
  }

  .headerBox .boldText,
  .headerBox .liteText {
    font-size: 20px;
  }
  .educationalTours_header,
  .liveTours_header {
    font-size: 0.8rem;
  }

  .intEduTourBox .imgBox img {
    height: 80px;
  }
  .intEduTourBox .titleBox {
    font-size: 9px;
    padding: 0px 2px;
  }

  .footercontainer {
    padding-bottom: 10px;
  }

  .termsConditionPara {
    font-size: 12px;
    display: block;
    text-align: center;
    padding-bottom: 4px;
  }

  element.style {
    height: 100%;
    display: flex;
    border: 1px solid;
    align-items: center;
  }

  .bannerHeader {
    font-size: 3rem;
    color: white;
    text-align: center;
    line-height: 3.5rem;
    letter-spacing: 12px;
  }

  .pageBanner {
    justify-content: center;
    padding-left: 0px;
    min-height: 200px;
  }

  .quoteSection {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .bannerSubHeader {
    margin: auto;
    margin-left: -10px;
  }

  .headerWrapper {
    max-width: 90%;
  }

  .chairmanPhoto img {
    max-width: 100%;
  }
  .blogCardHeader .css-3ogxn6-MuiButtonBase-root-MuiIconButton-root {
    padding: 0;
  }
  .itinerarySection {
    top: 86px;
  }

  .dayHeaderBox {
    top: 190px;
  }
}

@media (max-width: 431px) {
  /* .headBox {
    width: 374px !important;
  } */
  .link,
  .mm_logo {
    width: 60% !important;
  }
  .lIne {
    font-size: 45px !important;
    margin-left: -115px !important;
    margin-top: 5px !important;
  }
  .muiTab {
    margin-left: 0 !important;
  }
  .headIng {
    display: none;
  }
  .headIngPhone {
    display: block !important;
    padding-left: 40px !important;
  }
  .homeBannerQuote {
    margin-top: 10px;
    min-height: 40%;
    top: 40%;
  }
  .eduHeaderBox .eduInnerBox {
    font-size: 1.1rem !important;
  }
  #intEducationalSection .owl-nav {
    transform: scale(0.8);
  }
  .domEducationalSection .owl-nav {
    transform: scale(0.8);
  }
  .eduTourImg {
    height: 100% !important;
    width: 100% !important;
  }
  .gate img {
    margin-bottom: 20px !important;
    margin-left: 60px !important;
    width: 60% !important;
  }
  .sps img {
    padding-left: 40px !important;
    width: 45% !important;
    margin-bottom: 20px !important;
  }
  .sajs img {
    margin-left: 65px !important;
    width: 60% !important;
    margin-bottom: 20px !important;
  }
  .bs img {
    margin-left: 30px !important;
    width: 35% !important;
    margin-bottom: 20px !important;
  }
  .hrm img {
    margin-bottom: 20px !important;
    margin-left: 65px !important;
    width: 55% !important;
  }
  .kot img {
    margin-bottom: 20px !important;
    margin-left: 90px !important;
    width: 25vw !important;
  }
  .mbsColl {
    width: 100% !important;
  }
  .mbsColl img {
    margin-left: 60px !important;
    width: 60% !important;
  }
  .xav img {
    width: 65% !important;
    margin-left: 60px !important;
  }
  .ran {
    width: 100% !important;
  }
  .ran img {
    margin-bottom: 20px !important;
    margin-left: 30px !important;
    width: 35% !important;
  }
  .mbsSch {
    width: 100% !important;
  }
  .mbsSch img {
    margin-bottom: 20px !important;
    margin-left: 75px !important;
    width: 50% !important;
  }
  .lys {
    width: 100% !important;
  }
  .lys img {
    margin-left: 75px !important;
    width: 40% !important;
  }
  .hot img {
    margin-left: 30px !important;
    width: 45% !important;
  }
  .tuli {
    width: 100% !important;
  }
  .tuli img {
    margin-left: 60px !important;
    width: 35% !important;
  }
  .sparsh {
    width: 100% !important;
  }
  .sparsh img {
    margin-left: 33px !important;
    width: 50% !important;
  }
  .prud {
    width: 100% !important;
  }
  .prud img {
    margin-left: 82px !important;
    width: 50% !important;
  }
  .heri {
    width: 100% !important;
  }
  .heri img {
    margin-left: 63px !important;
    width: 59% !important;
  }
  .presi {
    width: 100% !important;
  }
  .presi img {
    margin-left: 63px !important;
    width: 59% !important;
  }
  .lotus {
    width: 100% !important;
  }
  .lotus img {
    margin-left: 63px !important;
    width: 59% !important;
  }
  .scottish {
    width: 100% !important;
  }
  .scottish img {
    margin-left: 24px !important;
    width: 75% !important;
  }
}
@media screen and (max-width: 600px) {
  .bannerImg {
    margin-top: 30px !important;
  }
}

@media (max-width: 840px) {
  .priorityTitle h3 {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .BadgeContainer__Inner-sc-pa6z2-0 {
    bottom: -30px !important;
    right: -45px !important;
    left: auto !important;
    transform: scale(0.6);
  }
  .feedbackIcon {
    position: relative;
    top: -68px;
    right: -105px;
    z-index: 999;
    transform: rotate(90deg) translateY(100%);
    transition: 0.3s;
  }
  .phonewhatsappicon {
    display: block;
  }
  .webwhatsappicon {
    display: none;
  }
}

@media (min-width: 1024px) {
  .phonewhatsappicon {
    display: none;
  }
  .webwhatsappicon {
    display: block;
  }
}

@media (max-width: 768px) {
  .feedbackIcon {
    position: relative;
    top: -68px;
    right: -105px;
    z-index: 999;
    transform: rotate(90deg) translateY(100%);
    transition: 0.3s;
  }
  .documentsTabsBox,
  .contentListBox {
    display: none;
  }

  .webview_priorityCarousel {
    display: none;
  }

  .w3view_priorityCarousel {
    display: block;
  }
}

@media (min-width: 1841px) {
  .priorityTitle h3 {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .discriptionBox p {
    font-size: 1.2rem;
  }
  .advertiseTags div:nth-child(2) {
    margin: 0 10px;
  }
  .phoneview_priorityCarousel {
    display: none;
  }
  .webview_priorityCarousel {
    display: block;
  }
}

@media (max-width: 1294px) {
  .parisbtnbox1 {
    display: none;
  }
}
.navTab {
  color: #fff !important;
  opacity: 1 !important;
  transition: 0.5s;
}
.u-text-1 {
  font-size: 4.225rem;
}
.tExT {
  font-size: 19px;
  font-weight: 500;
  font-family: "Josefin Sans", sans-serif;
}
.mainImage {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0.8rem;
}
.gallery .viDeo {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}
.gallery .viDeo:hover {
  transform: scale(0.98);
}
.recentEduBox .imgBox img {
  width: 100% !important;
  height: 255px !important;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;
}
.recentEduBox .imgBox img:hover {
  filter: grayscale(80%);
  transform: scale(1.02);
}
.oveRLay {
  position: fixed;
  top: 5%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  z-index: 999;
}
.oveRLay img {
  display: block;
  max-width: 60%;
  max-height: 100%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
#RecenTTour .owl-nav {
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 4%);
  border-radius: 25%;
  right: 10px;
  top: -60px;
  position: absolute;
  width: 100px;
  height: 40px;
  text-align: right;
  display: block;
}
#RecenTTour .owl-nav .owl-prev span {
  font-size: 40px;
  line-height: 10px;
}
#RecenTTour .owl-nav .owl-next span {
  font-size: 40px;
  line-height: 10px;
}

#RecenTTour .owl-nav .owl-prev {
  position: absolute;
  top: 6px;
  text-align: center;
  vertical-align: middle;
  left: 15px;
  width: 20%;
  height: 60%;
}
#RecenTTour .owl-nav .owl-next {
  position: absolute;
  top: 6px;
  text-align: center;
  vertical-align: middle;
  right: 15px;
  width: 20%;
  height: 60%;
}
#RecenTTour {
  margin-top: 30px;
}
.intEduBox .imgBox img {
  width: 100% !important;
  height: 270px !important;
}
.postCardDesc {
  color: white;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.blogTxt .readmorelink .rightIcon {
  display: none;
  font-size: 15px;
}

.blogTxt .readmorelink a:hover ~ .rightIcon {
  display: block;
}
.blogContenT {
  margin-left: 17%;
  margin-top: 2%;
}
.blogContenT iframe {
  width: 80%;
  height: 700px;
}
.flipBook {
  display: none;
}
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
.cta {
  display: none;
}
.OverLay {
  position: fixed;
  top: 5%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  z-index: 999;
}
.OverLay img {
  display: block;
  max-width: 60%;
  max-height: 100%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.pageBanner {
  min-height: 300px;
}
.pageBanner_goa {
  min-height: 300px;
}
.pageBanner_dubai {
  min-height: 300px;
}
.pageBanner_ahmedabad {
  min-height: 300px;
}
.pageBanner_bana {
  min-height: 300px;
}
.pageBanner_viet {
  min-height: 300px;
}
.pageBanner_halong {
  min-height: 300px;
}
.pageBanner_shimla {
  min-height: 300px;
}
.pageBanner_manali {
  min-height: 300px;
}
.pageBanner {
  z-index: 9;
  background: linear-gradient(to top right, #101c27, #184775, #2c3e50);
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}
.pageBanner_goa {
  z-index: 9;
  /* background: linear-gradient(to top right, #101c27, #184775, #2c3e50); */
  background-attachment: fixed;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}
.pageBanner_dubai {
  z-index: 9;
  /* background: linear-gradient(to top right, #101c27, #184775, #2c3e50); */
  background-attachment: fixed;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}
.pageBanner_ahmedabad {
  z-index: 9;
  /* background: linear-gradient(to top right, #101c27, #184775, #2c3e50); */
  background-attachment: fixed;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}
.pageBanner_bana {
  z-index: 9;
  /* background: linear-gradient(to top right, #101c27, #184775, #2c3e50); */
  background-attachment: fixed;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}
.pageBanner_viet {
  z-index: 9;
  /* background: linear-gradient(to top right, #101c27, #184775, #2c3e50); */
  background-attachment: fixed;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}
.pageBanner_halong {
  z-index: 9;
  /* background: linear-gradient(to top right, #101c27, #184775, #2c3e50); */
  background-attachment: fixed;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}
.pageBanner_shimla {
  z-index: 9;
  /* background: linear-gradient(to top right, #101c27, #184775, #2c3e50); */
  background-attachment: fixed;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}
.pageBanner_manali {
  z-index: 9;
  /* background: linear-gradient(to top right, #101c27, #184775, #2c3e50); */
  background-attachment: fixed;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  transition: 1s;
  background-size: 400% 400%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 60px;
  filter: contrast(1.4);
}
.pageBanner_goa {
  background-image: url(/public/images/places/goa/beach-goa-lable.jpg);
}
.pageBanner_dubai {
  background-image: url(/public/images/places/dubai/dubai-lable.jpg);
}
.pageBanner_ahmedabad {
  background-image: url(/public/images/places/ahmedabad/banner.jpg);
}
.pageBanner_bana {
  background-image: url(/public/images/places/banahills/bana-lable.jpg);
}
.pageBanner_viet {
  background-image: url(/public/images/places/vietnam/viet_banner.jpg);
}
.pageBanner_halong {
  background-image: url(/public/images/places/halongbay/12.jpg);
}
.pageBanner_shimla {
  background-image: url(/public/images/places/shimla/01.jpg);
}
.pageBanner_manali {
  background-image: url(/public/images/places/Manali/banner.jpeg);
}
.bannerBlogHeader {
  font-size: 6rem;
  color: white;
  line-height: 7.5rem;
}
.dharambannerBlogHeader {
  font-size: 6rem;
  color: white;
  line-height: 7.5rem;
}
.bannerBlogHeader_goa {
  font-size: 6rem;
  color: white;
  line-height: 7.5rem;
}
.bannerBlogHeader_dubai {
  font-size: 6rem;
  color: white;
  line-height: 7.5rem;
}
.bannerBlogHeader_ahmedabad {
  font-size: 6rem;
  color: #765959;
  line-height: 7.5rem;
}
.blogPara {
  margin-bottom: 60px;
  font-size: 1.3em;
  font-weight: 500;
  color: #304c67;
  text-align: justify;
  background-color: #fff;
  font-family: "Josefin Sans", sans-serif;
}
span.blogWritter {
  color: #2c3e50;
  font-weight: bold;
}
#intEducationalSection .owl-theme .owl-nav {
  margin-top: 20px !important;
}
#intEducationalSection .owl-nav,
.domEducationalSection .owl-nav {
  border-radius: 25px;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.04);
  height: 40px;
  position: absolute;
  right: 10px;
  text-align: right;
  top: -60px;
  width: 100px;
}

#intEducationalSection .owl-nav .owl-prev,
.domEducationalSection .owl-nav .owl-prev {
  height: 60%;
  left: 15px;
  position: absolute;
  text-align: center;
  top: 6px;
  vertical-align: middle;
  width: 20%;
}

#intEducationalSection .owl-nav .owl-prev span,
.domEducationalSection .owl-nav .owl-prev span {
  font-size: 40px;
  line-height: 10px;
}

#intEducationalSection .owl-nav .owl-next,
.domEducationalSection .owl-nav .owl-next {
  height: 60%;
  right: 15px;
  position: absolute;
  text-align: center;
  top: 6px;
  vertical-align: middle;
  width: 20%;
}

#intEducationalSection .owl-nav .owl-next span,
.domEducationalSection .owl-nav .owl-next span {
  font-size: 40px;
  line-height: 10px;
}

/* -------------------------67% View------------------------------ */

@media screen and (max-width: 2055px) {
  .otherBlogCard {
    width: 600px !important;
  }
}

/* -------------------------75% View------------------------------ */

@media screen and (max-width: 1825px) {
  .priorityTitle h3 {
    font-size: 16px !important;
  }
  .otherBlogCard {
    width: 530px !important;
  }
}

/* -------------------------80% View------------------------------ */

@media screen and (max-width: 1712px) {
  .priorityTitle h3 {
    font-size: 15px !important;
  }
  .otherBlogCard {
    width: 490px !important;
  }
  .tExT {
    font-size: 24px !important;
  }
  .mainImage {
    width: 500px !important;
  }
}

/* -------------------------90% View------------------------------ */

@media screen and (max-width: 1520px) {
  .priorityTitle h3 {
    font-size: 13px !important;
  }
  .otherBlogCard {
    width: 410px !important;
  }
  .mainImage {
    width: 480px !important;
  }
}

/* -------------------------100% View------------------------------ */

@media screen and (max-width: 1367px) {
  .reSize img {
    width: 100% !important;
    height: 100% !important;
  }
  .priorityTitle h3 {
    font-size: 11px !important;
  }
  .otherBlogCard {
    width: 370px !important;
  }
  .tExT {
    font-size: 20px !important;
  }
  .mainImage {
    width: 430px !important;
  }
}
@media screen and (max-width: 1024px) {
  .customContainer .multiDevice .video-js {
    width: 95%;
    border-radius: 10px;
  }
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .GaLlery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .u-text-1 {
    font-size: 3.5rem !important;
  }
  .tExT {
    font-size: 18px !important;
  }
  a.bUtToN {
    display: none;
    margin-top: 0 !important;
    width: 80px;
    height: 30px;
    text-align: center;
    font-size: 0.425rem !important;
  }
  .reSize img {
    height: 70% !important;
    width: 79% !important;
  }
  .mainImage {
    width: 300px !important;
    margin: 0 !important;
  }
  .flipBook {
    display: block !important;
  }
  .cta {
    margin: 0 !important;
    display: block !important;
    grid-template-columns: 1fr 1fr;
    gap: 1rem !important;
    text-align: center;
  }
  .cta p {
    font-size: 16px;
  }
  .BTN {
    font-size: 14px !important;
    padding: 0.35rem 1rem !important;
  }
  .BTN > span {
    font-size: 15px !important;
  }
  .blogContenT {
    display: none !important;
  }
  .blogHeader ul {
    padding: 0;
  }
  .subheaderOne {
    font-size: 22px !important;
    margin-bottom: 10px !important;
  }
  .subheader {
    font-size: 16px !important;
  }
  .videoMain {
    width: 100% !important;
    height: 50% !important;
  }
  .navTab {
    display: none !important;
  }
  .intEduBox {
    width: 100% !important;
  }
  .intEduBox .imgBox img {
    width: 100% !important;
    height: 200px !important;
  }
  .recentEduBox .imgBox img {
    height: 200px !important;
  }
  .instaImgBox {
    width: 190px;
    height: 124px;
  }
  .premium_edu_logo {
    display: none;
  }
  .premium_logo {
    width: 18vw;
  }
}
@media screen and (max-width: 822px) {
  .premium_edu_logo {
    display: none;
  }
  .premium_logo {
    width: 22vw;
  }
}
@media screen and (max-width: 770px) {
  .reSize img {
    height: 210px !important;
    width: 280px !important;
  }
  .instaImgBox {
    width: 180px;
    height: 112px;
  }
  .premium_edu_logo {
    display: none;
  }
  .premium_logo {
    width: 24vw;
  }
}
@media screen and (max-width: 600px) {
  body {
    overflow-x: hidden !important;
  }
  .customContainer .multiDevice .video-js {
    width: 100%;
  }
  .customContainer .Space {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
  .gallery .nOnE {
    display: none;
  }
  .u-text-1 {
    font-size: 2.5rem !important;
  }
  .tExT {
    font-size: 14px !important;
  }
  .reSize img {
    height: 45% !important;
    width: 40% !important;
  }
  #RecenTTour .owl-nav {
    transform: scale(0.8);
  }
  .overLay > span {
    font-size: 30px !important;
  }
  .mainImage {
    width: 80% !important;
    margin-left: 20px !important;
  }
  .blogContenT {
    margin: 0 !important;
  }
  .blogContenT iframe {
    width: 100% !important;
    height: 500px !important;
  }
  .bTN {
    display: none;
  }
  .blogContenT {
    display: none !important;
  }
  .flipBook {
    display: block !important;
  }
  .cta {
    margin: 0 !important;
    display: block !important;
    grid-template-columns: 1fr 1fr;
    gap: 1rem !important;
    text-align: center;
  }
  .cta p {
    font-size: 13px;
  }
  .BTN {
    font-size: 12px !important;
    padding: 0.15rem 0.75rem !important;
  }
  .BTN > span {
    font-size: 14px !important;
  }
  .blogHeader ul {
    padding: 0;
  }
  .subheaderOne {
    font-size: 18px !important;
    margin-bottom: 15px !important;
  }
  .subheader {
    font-size: 14px !important;
  }
  .disAble {
    display: none;
  }
  .blogPara {
    font-size: 1rem !important;
    width: 100% !important;
    font-family: "Josefin Sans", sans-serif !important;
  }
  .videoMain {
    width: 100% !important;
    height: 50% !important;
  }
  body .mcWRN {
    background-color: none;
  }
  .customToolbar {
    position: relative;
    height: 35px !important;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }
  .white_logo {
    display: none;
  }
  .mobileViewdLogo {
    display: block !important;
  }
  .navTab {
    display: none !important;
  }
  .intEduBox {
    width: 100% !important;
  }
  .intEduBox .imgBox img {
    width: 100% !important;
    height: 200px !important;
  }
  .recentEduBox .imgBox img {
    height: 100px !important;
  }
  .cardBlog {
    margin-bottom: 10px;
  }
  .pageBanner {
    justify-content: center;
    padding-left: 0px;
    min-height: 200px;
  }
  .headerWrapper {
    max-width: 90%;
  }
  .bannerBlogHeader {
    font-size: 2.6rem;
    color: white;
    text-align: center;
    line-height: 3.5rem;
    letter-spacing: 12px;
  }
  .bannerBlogHeader_goa {
    font-size: 2.2rem;
    color: white;
    text-align: center;
    line-height: 3.5rem;
    letter-spacing: 10px;
  }
  .bannerBlogHeader_dubai {
    font-size: 2.2rem;
    color: white;
    text-align: center;
    line-height: 3.5rem;
    letter-spacing: 10px;
  }
  .bannerBlogHeader_ahmedabad {
    color: #765959 !important;
    font-size: 1.8rem;
    color: white;
    font-weight: 700;
    text-align: center;
    line-height: 3.5rem;
    letter-spacing: 10px;
  }
  .dharambannerBlogHeader {
    font-size: 2rem;
    color: white;
    text-align: center;
    line-height: 2.5rem;
    letter-spacing: 12px;
  }
  .dharamBlogHead {
    font-size: 1.2rem;
    word-spacing: 1px;
  }
}
@media screen and (max-width: 432px) {
  .instaImgBox {
    width: 131px;
    height: 76px;
  }
  .premium_edu_logo {
    display: none;
  }
  .premium_logo {
    width: 43vw;
  }
}
@media screen and (max-width: 415px) {
  .reSize img {
    width: 170px;
  }
  .instaImgBox {
    width: 125px;
    height: 76px;
  }
  .premium_edu_logo {
    display: none;
  }
  .premium_logo {
    width: 40vw;
  }
}
@media screen and (max-width: 392px) {
  .instaImgBox {
    width: 114px;
    height: 76px;
  }
  .premium_edu_logo {
    display: none;
  }
  .premium_logo {
    width: 48vw;
  }
}
@media screen and (max-width: 362px) {
  .instaImgBox {
    width: 109px;
    height: 76px;
  }
}
@media screen and (max-width: 281px) {
  .css-1c3guxc-MuiButtonBase-root-MuiButton-root {
    min-width: 30px !important;
  }
}
