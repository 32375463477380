body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.expBtnBox{
  position: relative;
  border-radius: 0;
  z-index: 1;
  margin-left: 20px;
  display: inline-block;
}

.shadowBox{
  position: absolute;
  top: 10%;
  left: 4%;
  width: 100%;
  height: 40px;
  background-color: rgba(44, 62, 80, .2);
  z-index: 0;
  transition: .3s;
  border-radius: 5px;
  
}

.expBtn{
  outline: none;
  border: none;
  background-color: #2c3e50;
  font-size: 20px;
  padding: 0 10px;
  color: white;
  top: 0;
  border-radius: 5px;
  left: 0;
  height: 40px;
  position: relative;
  z-index: 7;
  transition: .3s;
  cursor: pointer;
}
  
.expBtn:active{
  transform: scale(1.1);
}

.expBtn:hover~.shadowBox{
  top: 15%;
  left: 6%;
  filter: blur(3px);
}
.expBtn:hover{
  left: -4px;
}